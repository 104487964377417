import React, { FC } from 'react'

export const GenderIcon: FC<{
    src: string
    size?: number
    className?: string
    alt?: string
}> = ({ src, size, className, alt }) => {
    return (
        <img
            src={src}
            width={size}
            height={size}
            className={className || 'gender-icon-img'}
            alt={alt}
        />
    )
}
