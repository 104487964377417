import React from 'react'
import { useTranslation } from 'react-i18next'

import {
    Col,
    Links,
    Row,
    Card,
    Stats,
    ProgressBar,
    Spinner
} from '../../components'
import { amountFormatter, capitalizeWord, constants } from 'helpers'
import { useAppSelector } from 'hooks'
import { FarmerIcon, VisitedHandshakeIcon } from 'assets'

interface VeMetricsProps {
    animationList: boolean
    hideBorder?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ve?: any
    connectUser: { country: string }
    canViewDeposits: boolean
    canViewDepositGap: boolean
    cardClickHandler: (filterOptions: string[], clientTab: number) => void
}

const isTargetValid = (value: number | null | undefined) => value !== 0 && value

export const VeMetrics = ({
    animationList,
    ve,
    connectUser,
    canViewDeposits,
    canViewDepositGap,
    cardClickHandler
}: VeMetricsProps) => {
    const { loading } = useAppSelector((store) => store.interaction)
    const interactionType = `${constants.FARMER_AWARENESS_SURVEY_NAME}-${constants.FARMER_AWARENESS_SURVEY_VERSION}`
    const loadingInteractions = loading && loading[interactionType]

    const countryConfig = JSON.parse(
        localStorage.getItem('country_config') || '{}'
    )
    const progressBarRange = {
        low: countryConfig?.sales_progress_medium_threshold,
        high: countryConfig?.sales_progress_high_threshold
    }

    const { t } = useTranslation()
    const getMonthYear = capitalizeWord(t('monthYear', { date: new Date() }))
    const { VILLAGE_PAGE_TABS } = constants

    const {
        target_monthly_sales: targetMonthlySales,
        sales_this_month: salesThisMonth,
        target_clients_active_this_month: targetClientsActiveThisMonth,
        clients_enrolled_this_month: clientsEnrolledThisMonth,
        target_monthly_enrolled: targetMonthlyEnrolled,
        clients_active_this_month: clientsActiveThisMonth,
        target_weekly_visited: targetWeeklyVisited,
        visits_this_week: visitsThisWeek,
        total_deposit: totalDeposit,
        gap: remainingDeposit,
        total_clients_registered_this_week: totalClientsRegisteredThisWeek,
        target_clients_registered_this_week: targetClientsRegisteredThisWeek
    } = ve || {
        target_monthly_sales: '',
        sales_this_month: '',
        target_clients_active_this_month: '',
        clients_enrolled_this_month: '',
        target_monthly_enrolled: '',
        clients_active_this_month: '',
        target_weekly_visited: '',
        visits_this_week: '',
        total_deposit: '',
        gap: ''
    }

    const hideBorder = !canViewDeposits && !canViewDepositGap

    const remainingSales =
        parseFloat(targetMonthlySales) - parseFloat(salesThisMonth)
    const salesPercentage = (
        (parseFloat(salesThisMonth) / parseFloat(targetMonthlySales)) * 100 || 0
    ).toFixed(0)
    const isTargetMonthlySalesValid = isTargetValid(targetMonthlySales)

    if (loadingInteractions) {
        return (
            <div className="target-list--spinner-parent">
                <Spinner
                    data-testid="loading-indicator"
                    size="50"
                    pageSpinner={false}
                    fullscreen={false}
                />
            </div>
        )
    }

    return (
        <Row>
            <Col md={12} className="profile-card-session">
                <div className="header">
                    <div className="header-icon">
                        {FarmerIcon}
                    </div>
                    <p className="session-header header-month">
                        {t('home.thisMonth')}
                    </p>
                    <h2 className="session-header header-farmers">
                        {t('profile.myFarmers')}
                    </h2>
                </div>
                <Row className="client-row weekly-objectives">
                    <Col className="client-card-parent">
                        <Card
                            className={
                                animationList
                                    ? 'client-card animate-show-3 animate-showed-3'
                                    : 'client-card animate-show-3'
                            }
                            shadow
                            onClick={() =>
                                cardClickHandler(
                                    [t('village.active')],
                                    VILLAGE_PAGE_TABS.CURRENT_CLIENT_TAB
                                )
                            }
                            data-testid="client-active-card"
                            id="active-client-card"
                        >
                            <div className="client-number">
                                {isTargetValid(targetClientsActiveThisMonth) && (
                                    <div className="target-progressbar" data-testid="target-progressbar">
                                           <ProgressBar
                                            percentage={(clientsActiveThisMonth / targetClientsActiveThisMonth) * 100}
                                            size="large"
                                            ranges={progressBarRange}
                                            />
                                            <div className="progress-numbers">
                                                <span
                                                    className={`progress-value ${clientsActiveThisMonth >= targetClientsActiveThisMonth ? 'blue' : ''}`}
                                                >
                                                    {clientsActiveThisMonth}
                                                </span>
                                                <span
                                                    className={`progress-max ${clientsActiveThisMonth >= targetClientsActiveThisMonth ? 'blue' : ''}`}
                                                >
                                                    {targetClientsActiveThisMonth}
                                                </span>
                                            </div>
                                    </div>
                                )}
                                {targetClientsActiveThisMonth <= 0 && (
                                    <div className="target-progressbar" data-testid="target-progressbar">
                                        <h1 className="client-number--content client-number--num">
                                            {clientsActiveThisMonth || 0}
                                        </h1>
                                    </div>
                                )}
                            </div>
                            <p className="client-card--header">
                                {t('profile.activeCustomers')}
                            </p>
                        </Card>
                    </Col>
                    <Col className="client-card-parent">
                        <Card
                            className={
                                animationList
                                    ? 'client-card second-client-card animate-show-4 animate-showed-4'
                                    : 'client-card second-client-card animate-show-4'
                            }
                            shadow
                            onClick={() =>
                                cardClickHandler(
                                    [
                                        t('village.active'),
                                        t('village.inactive')
                                    ],
                                    VILLAGE_PAGE_TABS.CURRENT_CLIENT_TAB
                                )
                            }
                            data-testid="client-enrolled-card"
                            id="registered-client-card"
                        >
                            <div className="client-number">

                                {isTargetValid(targetMonthlyEnrolled) && (
                                    <div className="target-progressbar" data-testid="target-progressbar">
                                        <ProgressBar
                                        percentage={(clientsEnrolledThisMonth / targetMonthlyEnrolled) * 100}
                                        size="large"
                                        ranges={progressBarRange}
                                        />
                                        <div className="progress-numbers">
                                            <span
                                                className={`progress-value ${clientsEnrolledThisMonth >= targetMonthlyEnrolled ? 'blue' : ''}`}
                                            >
                                                {clientsEnrolledThisMonth}
                                            </span>
                                            <span
                                                className={`progress-max ${clientsEnrolledThisMonth >= targetMonthlyEnrolled ? 'blue' : ''}`}
                                            >
                                                {targetMonthlyEnrolled}
                                            </span>
                                        </div>
                                    </div>
                                )}{targetMonthlyEnrolled <= 0 && (
                                    <div className="target-progressbar" data-testid="target-progressbar">
                                        <h1 className="client-number--content client-number--num">
                                            {clientsEnrolledThisMonth || 0}
                                        </h1>
                                    </div>
                                )}
                            </div>
                            <p className="client-card--header">
                                {t('profile.toRegister')}
                            </p>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col md={12} className="profile-card-session">
                    <Card
                        className={
                            animationList
                                ? 'performance-card-parent animate-show-2 animate-showed-2'
                                : 'animate-show-2'
                        }
                        shadow
                    >
                        <div
                            className="card-sale-parent"
                            style={{
                                border: hideBorder ? '' : 'none'
                            }}
                        >
                            <div className="card-sale">
                                <h2 className="card-sale--header">
                                    {t('home.sales')}{' '}
                                    <span className="card-sale--duration">
                                        {t('of')} {getMonthYear}
                                    </span>
                                </h2>
                                {isTargetMonthlySalesValid && (
                                    <Stats
                                        className=""
                                        data-testid="sales-percentage"
                                    >
                                        {salesPercentage}
                                    </Stats>
                                )}
                            </div>
                            <div className="card-progressbar">
                                <ProgressBar
                                    percentage={salesPercentage}
                                    size="large"
                                    ranges={progressBarRange}
                                />
                            </div>
                            <div className="progressbar-numbers">
                                <h1 className="progressbar-numbers--amount">
                                    {amountFormatter(
                                        salesThisMonth,
                                        connectUser.country
                                    )}{' '}
                                </h1>
                                {isTargetMonthlySalesValid && (
                                    <p className="progressbar-numbers--total">
                                        <span className="progressbar-numbers--of">
                                            {t('on')}
                                        </span>{' '}
                                        {amountFormatter(
                                            targetMonthlySales,
                                            connectUser.country
                                        )}{' '}
                                    </p>
                                )}
                            </div>
                        </div>
                        <hr />
                        <div className="sales-deposit-parent">
                            {isTargetMonthlySalesValid && (
                                <div className="sales-deposit">
                                    <p>{t('home.remainingSales')}</p>
                                    <p data-testid="remaining-sales">
                                        {amountFormatter(
                                            remainingSales > 0 && remainingSales,
                                            connectUser.country
                                        )}{' '}
                                    </p>
                                </div>
                            )}
                            {canViewDeposits && (
                                <div className="sales-deposit">
                                    <Links
                                        className="deposits-link"
                                        to="deposits"
                                        id="deposits-link"
                                        role="deposit-link"
                                    >
                                        {t('home.deposit')}
                                    </Links>
                                    <p>
                                        {amountFormatter(
                                            totalDeposit > 0 && totalDeposit,
                                            connectUser.country
                                        )}{' '}
                                    </p>
                                </div>
                            )}
                            {canViewDepositGap && (
                                <div
                                    className="sales-deposit"
                                    title="sales-deposit"
                                >
                                    <p>{t('home.remainingDeposit')}</p>
                                    <p>
                                        {amountFormatter(
                                            remainingDeposit,
                                            connectUser.country
                                        )}{' '}
                                    </p>
                                </div>
                            )}
                        </div>
                    </Card>
            </Col>
            <Col md={12} className="profile-card-session">
                <div className="header">
                    <div className="visit-icon">
                        {VisitedHandshakeIcon({})}
                    </div>
                    <p className="session-header header-month">
                        {t('home.thisWeek')}
                    </p>
                    <h2 className="session-header header-farmers">
                        {t('profile.myVisits')}
                    </h2>
                </div>
                <Row className="client-row weekly-objectives">
                    {
                        <Col className="client-card-parent">
                            <Card
                                className={
                                    animationList
                                        ? 'client-card second-client-card animate-show-4 animate-showed-4'
                                        : 'client-card second-client-card animate-show-4'
                                }
                                shadow
                                onClick={() =>
                                    cardClickHandler(
                                        [t('village.visitedThisWeek')],
                                        VILLAGE_PAGE_TABS.PREVIOUS_CLIENT_TAB
                                    )
                                }
                                data-testid="visited-client-card"
                                id="visited-client-card"
                            >
                                <div className="client-number">
                                    {isTargetValid(targetWeeklyVisited) && (
                                        <div className="target-progressbar" data-testid="target-progressbar">
                                            <ProgressBar
                                            percentage={(visitsThisWeek / targetWeeklyVisited) * 100}
                                            size="large"
                                            ranges={progressBarRange}
                                            />
                                            <div className="progress-numbers">
                                                <span
                                                    className={`progress-value ${visitsThisWeek >= targetWeeklyVisited ? 'blue' : ''}`}
                                                >
                                                    {visitsThisWeek}
                                                </span>
                                                <span
                                                    className={`progress-max ${visitsThisWeek >= targetWeeklyVisited ? 'blue' : ''}`}
                                                >
                                                    {targetWeeklyVisited}
                                                </span>
                                            </div>
                                        </div>
                                    )}{targetWeeklyVisited <= 0 && (
                                        <div className="target-progressbar" data-testid="target-progressbar">
                                            <h1 className="client-number--content client-number--num">
                                                {visitsThisWeek || 0}
                                            </h1>
                                        </div>
                                    )}
                                </div>
                                <p className="client-card--header">
                                    {t('profile.potentialClientsVisited')}
                                </p>
                            </Card>
                        </Col>
                    }
                    {
                        <Col className="client-card-parent">
                            <Card
                                className={
                                    animationList
                                        ? 'client-card second-client-card animate-show-4 animate-showed-4'
                                        : 'client-card second-client-card animate-show-4'
                                }
                                shadow
                                onClick={() =>
                                    cardClickHandler(
                                        [t('village.active')],
                                        VILLAGE_PAGE_TABS.PREVIOUS_CLIENT_TAB
                                    )
                                }
                                data-testid="registered-client-card"
                                id="registered-client-card"
                            >
                                <div className="client-number">
                                {isTargetValid(targetClientsRegisteredThisWeek) && (
                                        <div className="target-progressbar" data-testid="target-progressbar">
                                            <ProgressBar
                                            percentage={(totalClientsRegisteredThisWeek / targetClientsRegisteredThisWeek) * 100}
                                            size="large"
                                            ranges={progressBarRange}
                                            />
                                            <div className="progress-numbers">
                                                <span
                                                    className={`progress-value ${totalClientsRegisteredThisWeek >= targetClientsRegisteredThisWeek ? 'blue' : ''}`}
                                                >
                                                    {totalClientsRegisteredThisWeek}
                                                </span>
                                                <span
                                                    className={`progress-max ${totalClientsRegisteredThisWeek >= targetClientsRegisteredThisWeek ? 'blue' : ''}`}
                                                >
                                                    {targetClientsRegisteredThisWeek}
                                                </span>
                                            </div>
                                        </div>
                                    )}{targetClientsRegisteredThisWeek <= 0 && (
                                        <div className="target-progressbar" data-testid="target-progressbar">
                                            <h1 className="client-number--content client-number--num">
                                                {totalClientsRegisteredThisWeek || 0}
                                            </h1>
                                        </div>
                                    )}
                                </div>
                                <p className="client-card--header">
                                    {t('profile.newlyVisited')}
                                </p>
                            </Card>
                        </Col>
                    }
                </Row>
            </Col>
        </Row>
    )
}
