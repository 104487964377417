import { NotAvailableIcon } from 'assets'
import clientSvgs from 'assets/svg/clientSvgs'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'

type AgeGroupRoleType = {
    groupRole: string | undefined
    familyLeader: string | undefined
    age: number | undefined
}
export const ClientAgeGroupRole: FC<AgeGroupRoleType> = ({
    groupRole,
    age,
    familyLeader
}) => {
    const { t } = useTranslation()
    const { GroupLeaderIcon, FamilyLeaderIcon } = clientSvgs
    return (
        <div className="client-full-details--age-group-role-container">
            <div>
                <div
                    className={'client-full-details--age-group-role'}
                    aria-label="group-leader"
                >
                    {groupRole ? (
                        <span aria-label="group-leader-icon">
                            <GroupLeaderIcon />
                        </span>
                    ) : familyLeader ? (
                        <span aria-label="family-leader-icon">
                            <FamilyLeaderIcon />
                        </span>
                    ) : (
                        <span aria-label="not-leader-icon">
                            <NotAvailableIcon
                                width="20"
                                height="20"
                                color={colors.black}
                            />
                        </span>
                    )}
                </div>
                <p className="client-full-details--age-group-text">
                    {t('enrollment.groupRole')}
                </p>
            </div>
            <div>
                <div
                    className={`client-full-details--${
                        age ? 'age-group-role' : 'empty-age-group'
                    }`}
                    aria-label="client-age"
                >
                    <p>{age}</p>
                </div>
                <p className="client-full-details--age-group-text">
                    {t('enrollment.age')}
                </p>
            </div>
        </div>
    )
}
