import { notificationSchema } from 'database/schemas'
import { CollectionDefinition } from './rxTypes'

export enum CollectionNames {
    // eslint-disable-next-line no-unused-vars
    NOTIFICATIONS = 'notifications'
}

export const COLLECTION_DEFINITIONS = [
    {
        name: CollectionNames.NOTIFICATIONS,
        pullEndpoint: '/notifications/',
        pushEndpoint: '/notifications/',
        schema: notificationSchema,
        batchSize: 1000,
        reSyncInterval: 60 * 1000,
        idField: 'uuid',
        updatedAtField: 'updated_at',
        deletedField: '',
        filterField: 'updated_at',
        retryTime: 60 * 1000,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        extractFromResponse: (response: any) => response.data.data,
        formatModifiedDate: (date = '') => Date.parse(date as string),
        params: {
            order_by: 'created_date'
        }
    }
] as CollectionDefinition[]
