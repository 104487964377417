import React from 'react'
import { useTranslation } from 'react-i18next'
import { HappyFarmer } from 'assets'

interface SuccessMessageProps {
    firstName: string
    clientCategoryForIconColor:
        | 'clientRegistration'
        | 'clientModification'
        | 'default'
}

export const SuccessMessage = ({
    firstName,
    clientCategoryForIconColor
}: SuccessMessageProps) => {
    const { t } = useTranslation()

    const happyFarmerIconColorsAndText = {
        clientRegistration: {
            primary: '#A96778',
            secondary: '#A9677880',
            successMessageKey: 'successClientRegistration'
        },
        clientModification: {
            primary: '#A3743B80',
            secondary: '#A3743B80',
            successMessageKey: 'successClientModification'
        },
        default: {
            primary: '#186D2A',
            secondary: '#16A34A',
            successMessageKey: 'successClientEnrollment'
        }
    }

    return (
        <div className="client-details--success-modal">
            <div className="client-details--success-modal-header">
                <p className="client-details--success-modal-title">
                    {t('cardPayment.successful')}!
                </p>
            </div>
            <div className="client-details--success-modal-icon">
                <HappyFarmer
                    primaryColor={
                        happyFarmerIconColorsAndText[clientCategoryForIconColor]
                            .primary
                    }
                    secondaryColor={
                        happyFarmerIconColorsAndText[clientCategoryForIconColor]
                            .secondary
                    }
                />
            </div>
            <div className="client-details--success-modal-text">
                <p className="client-details--success-modal-message">
                    {t(
                        `clientDetails.${happyFarmerIconColorsAndText[clientCategoryForIconColor].successMessageKey}`,
                        {
                            firstName
                        }
                    )}
                </p>
            </div>
        </div>
    )
}
