export const VILLAGE_ID = 'village_id'
export const VILLAGE_ID_CLIENT_CODE = 'village_id_client_code'
export const INDEXED_DB_VERSION = 10
export const INDEXED_DB_NAME_PRODUCTION = 'connect-form'
export const RUN_TIME_CACHE_NAME = 'connect-global-cache-v1'
export const IMAGES_CACHE_NAME = 'connect-images'
export const EXPECTED_CACHES = [RUN_TIME_CACHE_NAME, IMAGES_CACHE_NAME]
export const BLANK_ID_PATH = 'blank-id-generator/'
export const MAX_RETRIES_POST_REQUEST = parseInt(
    process.env.REACT_APP_MAX_RETRIES_POST_REQUEST || '5'
)

/* eslint-disable no-unused-vars */
export enum CLIENT_TYPES {
    CurrentClient = 'current_client',
    PreviousClient = 'previous_client',
    AllClient = 'all_client'
}

export const OBJECT_STORE_NAMES = {
    BLANK_IDS_STORE_NAME: 'blank_ids_v2',
    POST_REQUESTS_STORE_NAME: 'post_requests_v1',
    USER_INFO_STORE_NAME: 'user_info_v1',
    VE_VILLAGES: ' ve_villages_v1',
    VILLAGE_ALL_CLIENT_NAME: 'village_all_client_v2',
    CLIENT_ORDERS_STORE: 'client_orders_store_v1',
    TRANSACTION_STORE_NAME: 'transactions_v1',
    TARGET_LIST: 'target_list_v1',
    INTERACTIONS: 'interactions_v2',
    PAYMENT_NOTIFICATION: 'payment_notification_v1'
}
