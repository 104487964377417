import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Modal, Row, Spinner, TopBar } from 'components'
import { CSSTransition } from 'react-transition-group'
import { constants } from 'helpers'
import { useTranslation } from 'react-i18next'
import { SingleNotification } from './SingleNotification'
import { FullNotificationModal } from './FullNotificationModal'
import { useAppSelector, useBindDispatch } from 'hooks'
import { NotificationType } from 'storeTypes'

export const Notification = () => {
    const { t } = useTranslation()
    const [animate, setAnimate] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [selectedNotification, setSelectedNotification] =
        useState<NotificationType | null>(null)
    const { markNotificationReadHandler } = useBindDispatch()
    const { notificationList, loading: isLoading } = useAppSelector(
        (state) => state.notifications
    )
    const nodeRef = useRef(null)

    useEffect(() => {
        setAnimate(true)
    }, [])

    const handleModalSelection = (notification: NotificationType) => {
        setShowModal(true)
        setSelectedNotification(notification)
        if (!notification.read_status) {
            markNotificationReadHandler(notification)
        }
    }

    return (
        <>
            <CSSTransition
                unmountOnExit
                timeout={constants.ANIMATION_TIMEOUT}
                in={animate}
                classNames="generic"
                appear
                nodeRef={nodeRef}
            >
                <div>
                    <Container>
                        <TopBar iconColor="#000000" transparentTopBar>
                            {t('messages')}
                        </TopBar>
                    </Container>
                    <div className="notification--body">
                        <div className="notification--container" ref={nodeRef}>
                            <Container>
                                <Row>
                                    <Col md={12}>
                                        {isLoading ? (
                                            <Spinner
                                                size={'90'}
                                                pageSpinner={true}
                                                fullscreen={true}
                                            />
                                        ) : (
                                            <div>
                                                {notificationList.length > 0 ? (
                                                    notificationList.map(
                                                        (notification) => (
                                                            <SingleNotification
                                                                notificationData={
                                                                    notification
                                                                }
                                                                onClick={() =>
                                                                    handleModalSelection(
                                                                        notification
                                                                    )
                                                                }
                                                                key={
                                                                    notification.id
                                                                }
                                                            >
                                                                {
                                                                    notification.message_fr
                                                                }
                                                            </SingleNotification>
                                                        )
                                                    )
                                                ) : (
                                                    <div className="notification--no-notification">
                                                        <p>
                                                            {t(
                                                                'noNotification'
                                                            )}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </CSSTransition>
            <Modal
                showModal={showModal}
                position="bottom"
                closeClickHandler={() => setShowModal(false)}
                showCloseIconBtn
            >
                <FullNotificationModal>
                    {selectedNotification?.message_fr}
                </FullNotificationModal>
            </Modal>
        </>
    )
}
