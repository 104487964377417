import { constants, formatDateToFullDate, sortHandler } from 'helpers'
import { groupBy, sumBy } from 'lodash'
import {
    CardPaymentPayloadType,
    TransactionKind,
    TransactionPayloadType
} from 'storeTypes'

export const calculateBalance = (transactions: TransactionPayloadType[] = []) =>
    sumBy(transactions, 'amount')
export const formatDateOnTransactions = (
    transactionList: TransactionPayloadType[]
) =>
    transactionList?.map((transaction) => ({
        ...transaction,
        date_received: transaction.date_received
            ? formatDateToFullDate(new Date(transaction.date_received))
            : ''
    }))

const createNonSyncedPayments = (payment: CardPaymentPayloadType) => ({
    ...payment,
    date_received: payment.date_received
        ? formatDateToFullDate(new Date(payment.date_received))
        : '',
    voucher_code: payment.transaction_id,
    type: TransactionKind.PENDING
})

const filterNonSyncedPayments = (
    clientPayments: CardPaymentPayloadType[],
    transactionIds: string[]
) =>
    clientPayments.map((payment) =>
        transactionIds?.includes(payment.transaction_id)
            ? ({} as CardPaymentPayloadType)
            : createNonSyncedPayments(payment)
    )

const consolidateTransactions = (
    nonSyncedPayments: CardPaymentPayloadType[],
    clientTransactions: TransactionPayloadType[]
): (CardPaymentPayloadType | TransactionPayloadType)[] => [
    ...nonSyncedPayments,
    ...clientTransactions
]

export const sortAndGroupTransactions = (
    transactions: (CardPaymentPayloadType | TransactionPayloadType)[]
) => {
    const sortedTransactions = sortHandler(
        transactions,
        'date_received',
        constants.SORT_TYPE.ALPHA_NUMERIC,
        constants.SORT_DIRECTION.DESCENDING
    )
    const formattedClientTransactions =
        formatDateOnTransactions(
            sortedTransactions as TransactionPayloadType[]
        ) || []
    return groupBy(formattedClientTransactions, 'date_received')
}

const processPaymentsWithTransactions = (
    clientPayments: CardPaymentPayloadType[],
    clientTransactions: TransactionPayloadType[]
) => {
    const transactionsIds = clientTransactions?.map(
        ({ voucher_code: voucherCode }) => voucherCode || ''
    )
    const nonSyncedPayments = filterNonSyncedPayments(
        clientPayments,
        transactionsIds
    )
    const consolidatedTransactions = consolidateTransactions(
        nonSyncedPayments,
        clientTransactions || []
    )

    return consolidatedTransactions
}

export const processPaymentsUpdate = (
    clientPayments: CardPaymentPayloadType[],
    clientTransactions: TransactionPayloadType[]
) => {
    if (clientPayments?.length > 0) {
        const processedPaymentsWithTransactions =
            processPaymentsWithTransactions(clientPayments, clientTransactions)

        return sortAndGroupTransactions(processedPaymentsWithTransactions)
    } else {
        return sortAndGroupTransactions(clientTransactions)
    }
}
