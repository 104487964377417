import { Card } from 'components/card'
import React, { FC } from 'react'
import { ClientDetails } from './ClientDetails'
import { ClientDetailsPayloadInterface } from 'storeTypes'
import { GenderIcon } from './GenderIcon'

type ClientDetailsCardProps = {
    profileIcon: JSX.Element
    genderIconUrl: string
    client: ClientDetailsPayloadInterface
}
export const ClientDetailsCard: FC<ClientDetailsCardProps> = ({
    profileIcon,
    genderIconUrl,
    client
}) => {
    const genderIcon = genderIconUrl ? (
        <GenderIcon
            src={genderIconUrl}
            size={70}
            alt={`${client?.ageGroup} ${client?.gender} icon`}
        />
    ) : null
    return (
        <Card shadow className={'height-100 animate-show-1 animate-showed-1'}>
            <ClientDetails
                client={client}
                profileIcon={profileIcon}
                genderIcon={genderIcon}
            />
        </Card>
    )
}
