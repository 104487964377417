import { buildCardContentFromDict } from 'helpers'
import React, { FC } from 'react'
import { ClientDetails } from './ClientDetails'
import { ClientDetailsPayloadInterface } from 'storeTypes'
import { Button } from 'components/button/Button'
import { useTranslation } from 'react-i18next'
import { ClientAgeGroupRole } from './ClientAgeGroupRole'
import { GenderIcon } from './GenderIcon'

type ClientFullDetailsType = {
    genderIconUrl?: string
    client: ClientDetailsPayloadInterface
    clientPhoneInfo: { formatPhone: string; clientPhone: string }
    otherClientData: Record<string, string | number>
    displayEditBtn: boolean
    isClientDuplicate: boolean
    btnTitle: string
    modifyClickHandler: () => void
}

export const ClientFullDetailsCard: FC<ClientFullDetailsType> = ({
    client,
    genderIconUrl,
    otherClientData,
    displayEditBtn,
    btnTitle,
    modifyClickHandler
}) => {
    const { t } = useTranslation()
    const clientDataToRemove = [
        t('age'),
        t('groupRole'),
        t('gender'),
        t('familyLeader')
    ]

    const filteredClientData = Object.entries(otherClientData).filter(
        ([key]) => !clientDataToRemove.includes(key)
    )

    const otherClientDataRender = buildCardContentFromDict(
        Object.fromEntries(filteredClientData),
        ['card-client-detail--bottom'].join(' ').trim()
    )

    const clientAge = otherClientData[t('age')]
    const clientGroupRole = otherClientData[t('groupRole')]
    const clientGender = otherClientData[t('gender')]
    const clientFamilyLeader = otherClientData[t('familyLeader')]

    const genderIcon = genderIconUrl ? (
        <GenderIcon
            src={genderIconUrl}
            size={50}
            alt={`${clientGender} icon`}
        />
    ) : null

    return (
        <div className="client-full-details">
            <ClientDetails
                client={client}
                profileIcon={genderIcon}
                showMultipleIcons={false}
            />
            <ClientAgeGroupRole
                groupRole={clientGroupRole as string}
                familyLeader={clientFamilyLeader as string}
                age={clientAge as number}
            />
            {otherClientDataRender}
            {displayEditBtn && (
                <Button
                    className="card-client-detail--button-interactions maroon-color-button"
                    style="primary"
                    iconPos="right"
                    size="xl"
                    onClick={modifyClickHandler}
                    data-testid="modify-click-handler"
                    id="modify-btn"
                >
                    {btnTitle}
                </Button>
            )}
        </div>
    )
}
