import {
    ExtractDocumentTypeFromTypedRxJsonSchema,
    RxDocument,
    RxCollection,
    RxJsonSchema,
    toTypedRxJsonSchema
} from 'rxdb'

export const notificationSchemaLiteral = {
    version: 0,
    primaryKey: 'uuid',
    type: 'object',
    properties: {
        id: { type: 'number' },
        uuid: { type: 'string', maxLength: 255 },
        user_id: { type: 'string' },
        delivery_date: { type: 'string' },
        type: { type: 'string' },
        sub_type: { type: 'string' },
        message_en: { type: 'string' },
        message_fr: { type: 'string' },
        read_status: { type: 'boolean' },
        created_date: { type: 'string' },
        updated_at: { type: 'string' }
    },
    required: ['read_status']
} as const

const schemaTyped = toTypedRxJsonSchema(notificationSchemaLiteral)

export type NotificationDocType = ExtractDocumentTypeFromTypedRxJsonSchema<
    typeof schemaTyped
>

export const notificationSchema: RxJsonSchema<NotificationDocType> =
    notificationSchemaLiteral

export type NotificationDocument = RxDocument<NotificationDocType>

export type NotificationCollection = RxCollection<NotificationDocType>
