import { NumberInput } from 'components'
import { validationRules } from 'helpers'
import { useValidation } from 'hooks'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ClientDetailsRouteContextType } from '../v3/types/routeContextType'
import { ContinueButton } from 'components/button/ContinueButton'
import { phoneIcon as PhoneIcon } from 'assets'
import { colors } from 'styles/colors'

export const PhoneNumber: FC<ClientDetailsRouteContextType> = ({
    phoneNumberHandler,
    phoneNumberInputValue,
    continueButtonText,
    userCountry,
    handleSubmit
}) => {
    const { t } = useTranslation()

    const countryPhoneLengthConfig: Record<string, number> = {
        Mali: 8,
        Senegal: 9,
        "Cote D'Ivoire": 10
    }

    const phoneNumberValidationRule = validationRules({
        country: userCountry,
        phone: phoneNumberInputValue
    })

    const { errors, validateForm } = useValidation(
        { phone: phoneNumberInputValue },
        phoneNumberValidationRule
    )

    useEffect(() => {
        if (phoneNumberInputValue && phoneNumberInputValue.length > 0) {
            validateForm()
        }
    }, [phoneNumberInputValue, userCountry])

    return (
        <div>
            <p className="enrollment--input-label form-title">
                <PhoneIcon
                    fillColor={colors.black}
                    color={colors.black}
                    direction="right"
                    width="30"
                    height="30"
                />
                {t('enrollment.clientPhone')}
            </p>
            <div className="enrollment--input-phone enrollment-button">
                <div>
                    <NumberInput
                        length={
                            countryPhoneLengthConfig[userCountry || 'Senegal']
                        }
                        onChangeNumberHandler={(number) => {
                            phoneNumberHandler?.(number)
                        }}
                        className={`${
                            countryPhoneLengthConfig[userCountry || 0] > 7 &&
                            'enrollment--input-number'
                        }`}
                        initialValue={phoneNumberInputValue}
                    />
                    {errors.phone && (
                        <p className="enrollment--input-error">
                            {errors.phone}
                        </p>
                    )}
                </div>
                {phoneNumberInputValue && !errors.phone && (
                    <ContinueButton
                        onClick={handleSubmit}
                        btnText={continueButtonText || t('continue')}
                    />
                )}
            </div>
        </div>
    )
}
