import transactionSourceIcons from 'assets/svg/transactionSourceIcons'
import { FilledPendingIcon } from 'assets/svg/svgs'
import { TransactionKind } from 'storeTypes'
import { TransactionKindConfigType } from '../types'

const {
    PaymentSuccessIcon,
    AwardCreditIcon,
    TruckDeliveryIcon,
    PaymentCorrectionIcon,
    ReimbursementCoinsIcon
} = transactionSourceIcons

export const getTransactionTypeSettings = (
    type: TransactionKind,
    amountColor: string
) => {
    const transactionTypeSettings: TransactionKindConfigType = {
        [TransactionKind.MOBILE_MONEY_PAYMENT]: {
            icon: PaymentSuccessIcon(),
            labelText: 'payment-success-icon',
            amountColor
        },
        [TransactionKind.MOBILE_MONEY_PAYMENT_REVERSAL]: {
            icon: PaymentCorrectionIcon({ color: '#A3743B' }),
            labelText: 'momo-reversal-icon',
            amountColor
        },
        [TransactionKind.VOUCHER_PAYMENT]: {
            icon: PaymentSuccessIcon(),
            labelText: 'payment-success-icon',
            amountColor
        },
        [TransactionKind.PENDING]: {
            icon: FilledPendingIcon(),
            labelText: 'payment-pending-icon'
        },
        [TransactionKind.AWARD]: {
            icon: AwardCreditIcon({}),
            labelText: 'award-icon',
            amountColor
        },
        [TransactionKind.AWARD_REVERSAL]: {
            icon: AwardCreditIcon({ color: '#A3743B' }),
            labelText: 'award-reversal-icon',
            amountColor
        },
        [TransactionKind.DELIVERY]: {
            icon: TruckDeliveryIcon({}),
            labelText: 'delivery-payment-icon',
            amountColor
        },
        [TransactionKind.PAYMENT_MODIFICATION]: {
            icon: PaymentCorrectionIcon({}),
            labelText: 'payment-modification-icon',
            amountColor
        },
        [TransactionKind.VOUCHER_PAYMENT_REVERSAL]: {
            icon: PaymentCorrectionIcon({ color: '#A3743B' }),
            labelText: 'voucher_reversal_icon',
            amountColor
        },
        [TransactionKind.REIMBURSEMENT]: {
            icon: ReimbursementCoinsIcon(),
            labelText: 'reimbursement-icon',
            amountColor
        }
    }

    return transactionTypeSettings[type as keyof TransactionKindConfigType]
}
