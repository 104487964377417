import { TFunction } from 'i18next'
import YoungFemaleAvatar from 'assets/clientAvatars/femaleAvatars/YoungFemaleAvatar.png'
import MiddleAgeFemaleAvatar from 'assets/clientAvatars/femaleAvatars/MiddleAgeFemaleAvatar.png'
import OldFemaleAvatar from 'assets/clientAvatars/femaleAvatars/OldFemaleAvatar.png'

import YoungMaleAvatar from 'assets/clientAvatars/maleAvatars/YoungMaleAvatar.png'
import MiddleAgeMaleAvatar from 'assets/clientAvatars/maleAvatars/MiddleAgeMaleAvatar.png'
import OldMaleAvatar from 'assets/clientAvatars/maleAvatars/OldMaleAvatar.png'

export const translateGender = (gender: string | undefined, t: TFunction) =>
    !gender ? '-' : gender.toLowerCase() === 'male' ? t('man') : t('woman')

export const getAgeProfile = (
    age: number
): 'young' | 'middle' | 'old' | null => {
    if (age >= 15 && age < 30) return 'young'
    if (age >= 30 && age <= 59) return 'middle'
    if (age >= 60) return 'old'
    return null
}

export const getGenderAgeProfile = (
    t: TFunction,
    gender: string,
    age: number
) => {
    const processedAge = getAgeProfile(age)
    switch (gender) {
        case t('man'):
            return {
                young: YoungMaleAvatar,
                middle: MiddleAgeMaleAvatar,
                old: OldMaleAvatar
            }[processedAge as string]
        case t('woman'):
            return {
                young: YoungFemaleAvatar,
                middle: MiddleAgeFemaleAvatar,
                old: OldFemaleAvatar
            }[processedAge as string]

        default:
            return null
    }
}
