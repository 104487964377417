import connectApi from '../api/connectApi'
import { constants, paymentStatus, searchHandler } from '../helpers'
import { Dispatch } from 'redux'
import {
    sendMessageToWorker,
    sendMessageToWorkerWithResponse
} from '../helpers/communication'
import {
    PaymentNotificationEnum,
    PaymentNotificationActionType,
    CardPaymentPayloadType,
    NotificationEnum,
    NotificationType
} from '../storeTypes'
import { ErrorResponse } from '../types'
import { PersistentStorage } from 'database/PersistentStorage'
import { CollectionNames } from 'database/constants'

export const paymentNotificationAction =
    (connectUser: string) =>
    async (dispatch: Dispatch<PaymentNotificationActionType>) => {
        const { VE_CARD_PAYMENT } = constants.endpoints

        const { fetchedPaymentData: initialPaymentData } =
            await sendMessageToWorkerWithResponse({
                'get payment notification list': {}
            })

        if (initialPaymentData?.length < 1 || !initialPaymentData) {
            dispatch({
                type: PaymentNotificationEnum.FETCHING_PAYMENT_NOTIFICATION
            })
        }
        try {
            const { data } = await connectApi.get(VE_CARD_PAYMENT, {
                params: {
                    memo: connectUser
                }
            })
            const { fetchedPaymentData } =
                await sendMessageToWorkerWithResponse({
                    'store payment notification': data
                })
            dispatch({
                type: PaymentNotificationEnum.FETCH_PAYMENT_NOTIFICATION_SUCCESS,
                payload: fetchedPaymentData
            })
        } catch (error: unknown) {
            if (error instanceof ErrorResponse) {
                dispatch({
                    type: PaymentNotificationEnum.FETCH_PAYMENT_NOTIFICATION_FAILURE,
                    payload: error.response.data.detail
                })
            } else {
                dispatch({
                    type: PaymentNotificationEnum.FETCH_PAYMENT_NOTIFICATION_FAILURE,
                    payload: 'Un problème est survenu'
                })
            }
        }
    }

export const storePaymentInIndexedDbAction =
    (data: CardPaymentPayloadType) => (dispatch: Dispatch) => {
        sendMessageToWorker({
            'store payment notification': [data]
        })
        dispatch({ type: '' })
    }

const paymentFilterByStatus = (
    paymentData: CardPaymentPayloadType,
    selectedFilter: string
) => {
    if (selectedFilter === constants.PAYMENT_NOTIFICATION_FILTERS.ALL) {
        return true
    }
    return paymentStatus(paymentData?.status || '') === selectedFilter
}

export const handlePaymentNotificationFilter = (
    selectedFilter: string,
    paymentData: CardPaymentPayloadType[]
): CardPaymentPayloadType[] => {
    const filterProcessedList = paymentData.filter((data) =>
        paymentFilterByStatus(data, selectedFilter)
    )
    return filterProcessedList
}

export const filterPaymentNotificationAction =
    (selectedFilter: string) =>
    async (dispatch: Dispatch<PaymentNotificationActionType>) => {
        const { fetchedPaymentData } = await sendMessageToWorkerWithResponse({
            'get payment notification list': {}
        })
        const filteredPaymentNotification = handlePaymentNotificationFilter(
            selectedFilter,
            fetchedPaymentData
        )
        dispatch({
            type: PaymentNotificationEnum.FILTERED_PAYMENT_NOTIFICATION,
            payload: filteredPaymentNotification as CardPaymentPayloadType[]
        })
    }

export const searchPaymentNotificationAction =
    (searchQuery: string) =>
    async (dispatch: Dispatch<PaymentNotificationActionType>) => {
        const { fetchedPaymentData } = await sendMessageToWorkerWithResponse({
            'get payment notification list': {}
        })
        const searchQueryObj = {
            transaction_id: searchQuery,
            client_code: searchQuery
        }
        const searchResult = searchHandler(fetchedPaymentData, searchQueryObj)
        dispatch({
            type: PaymentNotificationEnum.SEARCHED_PAYMENT_NOTIFICATION,
            payload: searchResult as CardPaymentPayloadType[]
        })
    }

export const markNotificationReadAction =
    (notification: NotificationType) => async () => {
        const rxInstance = await PersistentStorage.get()
        await rxInstance.addCollectionByName(CollectionNames.NOTIFICATIONS)

        await rxInstance.upsert(CollectionNames.NOTIFICATIONS, {
            ...notification,
            read_status: true
        })
    }

export const fetchUnreadNotificationsAction =
    () => async (dispatch: Dispatch) => {
        try {
            dispatch({ type: NotificationEnum.FETCHING_NOTIFICATION })

            const rxInstance = await PersistentStorage.get()
            await rxInstance.addCollectionByName(CollectionNames.NOTIFICATIONS)
            const hasStopped = rxInstance.hasStopped(
                CollectionNames.NOTIFICATIONS
            )

            const gottenNotifications = await rxInstance.find(
                CollectionNames.NOTIFICATIONS
            )

            dispatch({
                type: NotificationEnum.FETCH_NOTIFICATION_SUCCESS,
                payload: gottenNotifications
            })

            hasStopped &&
                rxInstance.syncCollection(
                    {},
                    {},
                    CollectionNames.NOTIFICATIONS,
                    (notifications: NotificationType[]) => {
                        const unreadNotifications = notifications.filter(
                            (notification) => !notification.read_status
                        )

                        dispatch({
                            type: NotificationEnum.HAS_UNREAD_NOTIFICATIONS,
                            payload: unreadNotifications.length > 0
                        })
                        dispatch({
                            type: NotificationEnum.FETCH_NOTIFICATION_SUCCESS,
                            payload: notifications
                        })
                    }
                )
        } catch (error: unknown) {
            if (error instanceof ErrorResponse) {
                dispatch({
                    type: NotificationEnum.FETCH_NOTIFICATION_FAILURE,
                    payload: error.response.data.detail
                })
            } else {
                dispatch({
                    type: NotificationEnum.FETCH_NOTIFICATION_FAILURE,
                    payload: 'Un problème est survenu'
                })
            }
        }
    }
