import {
    NotificationActionType,
    NotificationEnum,
    NotificationPayloadType,
    PaymentNotificationActionType,
    PaymentNotificationEnum,
    PaymentPayloadType
} from '../storeTypes'
import { sortHandler, constants } from 'helpers'

export const paymentNotificationReducer = (
    state: PaymentPayloadType = { paymentData: [], loading: false, error: '' },
    action: PaymentNotificationActionType
): PaymentPayloadType => {
    switch (action.type) {
        case PaymentNotificationEnum.FETCHING_PAYMENT_NOTIFICATION:
            return {
                paymentData: [],
                loading: true,
                error: ''
            }
        case PaymentNotificationEnum.FETCH_PAYMENT_NOTIFICATION_SUCCESS:
            return {
                loading: false,
                error: '',
                paymentData: sortHandler(
                    action.payload,
                    'date_received',
                    constants.SORT_TYPE.DATE,
                    constants.SORT_DIRECTION.DESCENDING
                ) as []
            }
        case PaymentNotificationEnum.FETCH_PAYMENT_NOTIFICATION_FAILURE:
            return {
                loading: false,
                error: action.payload || 'Un problème est survenu',
                paymentData: []
            }
        case PaymentNotificationEnum.FILTERED_PAYMENT_NOTIFICATION:
            return {
                ...state,
                paymentData: sortHandler(
                    action.payload,
                    'date_received',
                    constants.SORT_TYPE.DATE,
                    constants.SORT_DIRECTION.DESCENDING
                ) as []
            }
        case PaymentNotificationEnum.SEARCHED_PAYMENT_NOTIFICATION:
            return {
                ...state,
                paymentData: sortHandler(
                    action.payload,
                    'date_received',
                    constants.SORT_TYPE.DATE,
                    constants.SORT_DIRECTION.DESCENDING
                ) as []
            }
        default:
            return state
    }
}

export const notificationReducer = (
    state: NotificationPayloadType = {
        notificationList: [],
        loading: false,
        error: '',
        hasUnreadNotifications: false
    },
    action: NotificationActionType
): NotificationPayloadType => {
    switch (action.type) {
        case NotificationEnum.FETCHING_NOTIFICATION:
            return {
                ...state,
                notificationList: [],
                loading: true,
                error: ''
            }
        case NotificationEnum.FETCH_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                notificationList: sortHandler(
                    action.payload,
                    'delivery_date',
                    constants.SORT_TYPE.DATE,
                    constants.SORT_DIRECTION.DESCENDING
                ) as []
            }
        case NotificationEnum.FETCH_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload || 'Un problème est survenu',
                notificationList: []
            }
        case NotificationEnum.MARK_NOTIFICATION_READ:
            return {
                ...state,
                notificationList: action.payload
            }
        case NotificationEnum.HAS_UNREAD_NOTIFICATIONS:
            return {
                ...state,
                hasUnreadNotifications: action.payload
            }
        default:
            return state
    }
}
