import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import clientSvgs from 'assets/svg/clientSvgs'
import { NotAvailableIcon } from 'assets'
import { ContinueButton } from 'components/button/ContinueButton'
import { capitalizeWord, separateWord } from 'helpers'

interface GroupRoleOption {
    key: string
    icon: JSX.Element
    label: string
}

export const GroupRoleForm: FC<{
    attribute: string
    nextStepHandler: () => void
    selectedGroupRole: Record<string, boolean>
    setSelectedGroupRole: React.Dispatch<
        React.SetStateAction<Record<string, boolean>>
    >
}> = ({
    attribute,
    nextStepHandler,
    selectedGroupRole,
    setSelectedGroupRole
}) => {
    const { FamilyLeaderIcon, GroupLeaderIcon, GroupRoleIcon } = clientSvgs
    const { t } = useTranslation()

    const groupRoleOptions: GroupRoleOption[] = useMemo(
        () => [
            {
                key: 'familyLeader',
                icon: <FamilyLeaderIcon />,
                label: capitalizeWord(separateWord(t('familyLeader')))
            },
            {
                key: 'groupLeader',
                icon: <GroupLeaderIcon size="40" />,
                label: capitalizeWord(separateWord(t('groupRole')))
            }
        ],
        [t, FamilyLeaderIcon, GroupLeaderIcon]
    )

    const renderGroupRoleOptions = () =>
        groupRoleOptions.map(({ key, icon, label }) => (
            <div
                key={key}
                className={`group-role--${
                    selectedGroupRole?.[key as keyof typeof selectedGroupRole]
                        ? 'selected'
                        : 'icon-text'
                }`}
                onClick={() =>
                    setSelectedGroupRole({
                        ...selectedGroupRole,
                        notLeader: false,
                        [key]: !selectedGroupRole[key]
                    })
                }
                aria-label={key}
            >
                {icon}
                <p>{label}</p>
            </div>
        ))

    return (
        <>
            <p className="enrollment--input-label form-title">
                <GroupRoleIcon />
                {`${t(`enrollment.${attribute}`)}?`}
            </p>
            <div className="group-role-container">
                <div className="group-role">{renderGroupRoleOptions()}</div>
                <div className="no-group-role">
                    <div
                        className={`group-role--${
                            selectedGroupRole.notLeader
                                ? 'selected'
                                : 'icon-text'
                        } no-group-role`}
                        onClick={() =>
                            setSelectedGroupRole({
                                groupLeader: false,
                                familyLeader: false,
                                notLeader: !selectedGroupRole.notLeader
                            })
                        }
                        aria-label="notLeader"
                    >
                        <NotAvailableIcon
                            width="40"
                            height="40"
                            color="black"
                        />
                        <p>{t('notLeader')}</p>
                    </div>
                </div>
            </div>

            {(selectedGroupRole.groupLeader ||
                selectedGroupRole.familyLeader ||
                selectedGroupRole.notLeader) && (
                <ContinueButton
                    onClick={nextStepHandler}
                    className="enrollment-base--continue-btn"
                />
            )}
        </>
    )
}
