import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ContinueButton } from 'components/button/ContinueButton'
import { GenderType } from '../enrollmentTypes'
import { getGenderAgeProfile } from 'pages/village/helpers/villageClientHelpers'
import { GenderIcon } from 'components/client/GenderIcon'

type GenderLabelKey = 'man' | 'woman'
type GenderOptions = {
    key: GenderType
    labelKey: GenderLabelKey
    avatar: string
}

export const GenderForm: FC<{
    attribute: string
    nextStepHandler: () => void
    setSelectedGender: React.Dispatch<React.SetStateAction<GenderType>>
    selectedGender: GenderType
    selectedAge: number | null
}> = ({
    attribute,
    nextStepHandler,
    setSelectedGender,
    selectedGender,
    selectedAge
}) => {
    const { t } = useTranslation()
    const GENDER_OPTIONS: GenderOptions[] = [
        {
            key: 'female',
            labelKey: 'woman',
            avatar: getGenderAgeProfile(t, t('woman'), selectedAge as number)
        },
        {
            key: 'male',
            labelKey: 'man',
            avatar: getGenderAgeProfile(t, t('man'), selectedAge as number)
        }
    ]
    return (
        <>
            <p className="enrollment--input-label">
                {t(`enrollment.${attribute}`)}
            </p>
            <div className="gender-icon-container">
                {GENDER_OPTIONS.map(({ key, labelKey, avatar }) => (
                    <div
                        key={key}
                        className="gender-icon"
                        onClick={() => setSelectedGender(key)}
                    >
                        <GenderIcon
                            src={avatar}
                            size={100}
                            className={
                                selectedGender === key
                                    ? 'gender-icon-img-selected'
                                    : 'gender-icon-img'
                            }
                        />
                        <p
                            className={
                                selectedGender === key
                                    ? 'gender-selected-text'
                                    : ''
                            }
                        >
                            {t(labelKey)}
                        </p>
                    </div>
                ))}
            </div>
            {selectedGender && (
                <ContinueButton
                    onClick={nextStepHandler}
                    className="enrollment-base--continue-btn"
                />
            )}
        </>
    )
}
