import React, { FC, useMemo, useState } from 'react'
import { arrowDownIcon } from 'assets/svg/svgs.js'
import { useTranslation } from 'react-i18next'
import { ContinueButton } from 'components/button/ContinueButton'

export const AgeInput: FC<{
    attribute: string
    nextStepHandler: () => void
    setSelectedAge: React.Dispatch<
        React.SetStateAction<number | null | undefined>
    >
    selectedAge: number | null | undefined
}> = ({ attribute, nextStepHandler, setSelectedAge, selectedAge }) => {
    const ages: number[] = useMemo(
        () => Array.from({ length: 120 - 15 + 1 }, (_, i) => i + 15),
        []
    )
    const { t } = useTranslation()
    const [showAgeList, setShowAgeList] = useState(false)
    return (
        <>
            <p className="enrollment--input-label">
                {t(`enrollment.${attribute}`)}
            </p>
            <div
                className={`age-form-container ${
                    showAgeList && 'age-form-container-placement'
                } ${selectedAge && 'age-form-container-selected'}`}
                onClick={() => setShowAgeList(!showAgeList)}
            >
                <p className={selectedAge ? 'age-item' : ''}>
                    {selectedAge || t('enrollment.ageSelectionPlaceholder')}
                </p>
                <div>{arrowDownIcon}</div>
            </div>
            {showAgeList && (
                <div className="age-item-container">
                    {ages.map((age, index) => (
                        <p
                            key={index}
                            className="age-item"
                            onClick={() => {
                                setSelectedAge?.(age)
                                setShowAgeList(false)
                            }}
                        >
                            {age}
                        </p>
                    ))}
                </div>
            )}
            {selectedAge && (
                <ContinueButton
                    onClick={nextStepHandler}
                    className="enrollment-base--continue-btn"
                />
            )}
        </>
    )
}
