import {
    CashHandIcon,
    MyAgroLogo,
    NotificationIcon,
    RightArrowIcon
} from 'assets'
import React, { FC, ReactNode } from 'react'

type FullNotificationModalType = {
    children?: ReactNode
}

export const FullNotificationModal: FC<FullNotificationModalType> = ({
    children = ''
}) => {
    return (
        <div>
            <div className="top-close-bg"></div>
            <div className="status-icon">
                <NotificationIcon width="37px" height="42px" />
            </div>
            <div className="status-notification">
                <CashHandIcon />
                <RightArrowIcon />
                <MyAgroLogo size={'60px'} />
            </div>
            <div className="modal-content-parent">
                <p
                    className="modal-content"
                    aria-label="notification-modal-content"
                >
                    {children}
                </p>
            </div>
        </div>
    )
}
