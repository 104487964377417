import React, { FC } from 'react'
import { Button } from './Button'
import { useTranslation } from 'react-i18next'

export const ContinueButton: FC<{
    onClick: () => void
    btnText?: string
    className?: string
}> = ({ onClick, btnText, className }) => {
    const { t } = useTranslation()
    return (
        <div className={className}>
            <Button
                style="primary"
                className="client-details--bottom-button"
                onClick={onClick}
            >
                {btnText || t('continue')}
            </Button>
        </div>
    )
}
