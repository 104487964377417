import React, { useEffect, useMemo, useState, useRef, useContext } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useSelector } from 'react-redux'
import {
    useParams,
    useNavigate,
    useSearchParams,
    useLocation
} from 'react-router-dom'
import {
    Col,
    Container,
    Row,
    TopBar,
    CardClientDetails,
    Button,
    Spinner,
    Modal
} from '../../components'
import {
    useBackAction,
    useBindDispatch,
    useGetStorage,
    useGetClient
} from 'hooks'
import { VillageClientPacket, VillageClientPayment } from './'
import {
    constants,
    generateUuidv4,
    getConnectUser,
    isClientInfoComplete,
    routes
} from '../../helpers'
import { useTranslation } from 'react-i18next'
import ClientStatus from '../../components/card/ClientStatus'
import { ClientStatus as ClientStatusEnum } from '../../storeTypes/clientTypes'
import { findPermission } from '../../helpers/findPermission'
import { ClientInteractions, InteractionOutcomes } from '../../types'
import { AppModalContext } from '../../components/appModal/AppModalProvider'
import { ConfirmDuplicateFlagInteraction } from 'components/modal/ConfirmDuplicateFlagInteraction'
import { getClientTransactionDetails } from 'pages/payment/helpers/getClientTransactionData'
import { buildPaymentSummaryProps } from 'pages/payment/helpers/buildPaymentSummaryProps'
import { translateGender } from './helpers/villageClientHelpers'
import { isAnyDelivered } from 'helpers/isAnyDelivered'

export const VillageClientDetails = () => {
    const [animate, setAnimate] = useState(false)
    const [animationList, setAnimationList] = useState(false)
    const [displayModal, setDisplayModal] = useState(false)
    const { displayAppModal: showInteractionFeedbackModal } =
        useContext(AppModalContext)

    const [searchParams] = useSearchParams()
    const reload = searchParams.get('reload')
    const fromURL = searchParams.get('from')

    const { pathname } = useLocation()

    const nodeRef = useRef(null)

    const { t } = useTranslation()
    const navigate = useNavigate()

    const { clientOrders } = useSelector((state) => state.clientOrders)

    const { transactions } = useSelector((state) => state.transactions)

    const [clientPackages, setClientPackages] = useState([])

    const {
        selectVillageClientHandler,
        navbarHandler,
        updateSingleClientBalAndSmsHandler,
        sendInteractionsActionHandler,
        clearSingleClientDataHandler,
        fetchClientOrdersHandler,
        resetClientOrdersHandler,
        clientDetailsEnrollmentHandler,
        transactionsHandler
    } = useBindDispatch()
    const { villageId, clientId } = useParams()
    const { country, veVillages, username } = getConnectUser()
    const { client, updateClient, loadingClient } = useGetClient(clientId)

    const userPermissions = useGetStorage(constants.USER_PERMISSIONS)
    const orders = useGetStorage(constants.SINGLE_CLIENT_ORDERS_KEY)

    // handles on device back button press or back swipe
    useBackAction(fromURL || `/village/${villageId}`)

    const canModifyClient = !client?.duplicate

    const displayVisitButton =
        findPermission(userPermissions, constants.CAN_RECORD_HOME_VISIT) &&
        canModifyClient

    // get village name
    const { name: villageName } = veVillages.find(
        ({ sf_id: sfId }) => sfId === villageId
    ) || { name: '' }
    const { openVillagePageHandler } = useBindDispatch()

    const isClientCertified = useMemo(() => {
        return client?.certified_for_delivery === 'True'
    }, [client])

    useEffect(() => {
        if (reload) {
            updateSingleClientBalAndSmsHandler({
                villageId,
                clientId
            })
        }
    }, [reload])

    // show menu bar
    useEffect(() => {
        setAnimate(true)
        navbarHandler(constants.SHOW_NAVBAR)
        document.title = t('clientDetails.pageTitle')

        return () => {
            clearSingleClientDataHandler()
        }
    }, [])

    useEffect(() => {
        selectVillageClientHandler({ clientId, villageId })
        fetchClientOrdersHandler(clientId, villageId)
    }, [])

    useEffect(() => {
        if (transactions?.length <= 0) {
            transactionsHandler({ villageId })
        }
    }, [transactions?.length])

    useEffect(() => {
        if (clientOrders?.length > 0) {
            setClientPackages(clientOrders)
            localStorage.setItem(
                constants.SINGLE_CLIENT_ORDERS_KEY,
                JSON.stringify(clientOrders)
            )
        } else if (orders) {
            setClientPackages(orders)
        }
    }, [clientOrders])

    useEffect(() => {
        return () => {
            resetClientOrdersHandler()
        }
    }, [])

    const getCardSaleUrl = () => {
        return `/cards/card-sale?client-code=${client?.client_code}&village-id=${villageId}`
    }

    const saveClientInLocalStore = (clientData) => {
        localStorage.setItem(
            constants.CLIENT_FOR_ENROLLMENT_STORE,
            JSON.stringify({
                ...clientData,
                goal_items: clientPackages?.filter((item) => !item.delivered)
            })
        )
    }

    const modifyHandler = (modificationType, nextURL = '') => {
        if (modificationType) {
            client.form_type = 'modification'
            client.village_name = villageName
            client.village = villageId
            const next = nextURL ? `&next=${nextURL}` : ''
            // Navigate to the new enrollment flow
            saveClientInLocalStore({
                ...client,
                modification: modificationType.includes('package')
                    ? constants.CLIENT_MODIFICATION_TYPES.PACKAGES
                    : constants.CLIENT_MODIFICATION_TYPES.DETAILS
            })
            return navigate(
                `/${routes.enroll}/${client.village}?from=${pathname}${next}`
            )
        }
    }

    const backNavigationHandler = () => {
        openVillagePageHandler({
            filterOptions: '',
            villageId,
            clientTab: constants.VILLAGE_PAGE_TABS.CURRENT_CLIENT_TAB
        })
        navigate(fromURL || `/village/${villageId}`)
    }

    const openVisitPageHandler = () => {
        if (isClientInfoComplete(client)) {
            localStorage.setItem(
                constants.CLIENT_FOR_VISIT_STORE,
                JSON.stringify({
                    clientCode: client.client_code,
                    villageId: client.village_id || villageId,
                    clientName: client.firstname,
                    origin: constants.ORIGIN_CLIENT_DETAIL
                })
            )
            return navigate('/visit')
        } else {
            modifyHandler(constants.CLIENT_MODIFICATION_TYPES.DETAILS, '/visit')
        }
    }

    const markClientAsDuplicate = () => {
        clientDetailsEnrollmentHandler({
            clientDetailsPayload: {
                ...client,
                duplicate: true,
                village: villageId,
                form_type: 'modification',
                username,
                uuid: generateUuidv4(),
                date: new Date().toISOString()
            }
        })
        sendInteractionsActionHandler({
            client_code: clientId,
            user_id: username,
            outcome: InteractionOutcomes.DUPLICATE,
            client_status: client.client_status,
            survey_name: constants.TARGET_LIST_SURVEY_NAME,
            survey_version: constants.TARGET_LIST_SURVEY_VERSION,
            village_id: villageId
        })
        showInteractionFeedbackModal(t('clientDetails.interactionRecorded'))
        setDisplayModal(false)
        updateClient({
            ...client,
            duplicate: true
        })
    }

    const interactionHandler = (interaction) => {
        if (interaction.name === ClientInteractions.DUPLICATE_FLAG) {
            return setDisplayModal(true)
        }

        sendInteractionsActionHandler({
            ...interaction,
            client_code: clientId,
            user_id: username,
            outcome: interaction.outcome,
            client_status: client.client_status,
            survey_name: constants.TARGET_LIST_SURVEY_NAME,
            survey_version: constants.TARGET_LIST_SURVEY_VERSION,
            village_id: villageId
        })
        showInteractionFeedbackModal(t('clientDetails.interactionRecorded'))
        if (!isClientInfoComplete(client)) {
            const nextUrl =
                interaction.name === ClientInteractions.CARD_PAYMENT
                    ? getCardSaleUrl()
                    : ''
            localStorage.setItem(
                constants.CLIENT_FOR_ENROLLMENT_STORE,
                JSON.stringify(client)
            )
            return modifyHandler(
                constants.CLIENT_MODIFICATION_TYPES.DETAILS,
                nextUrl
            )
        }
        if (interaction.name === ClientInteractions.CARD_PAYMENT) {
            navigate(getCardSaleUrl())
        }
    }

    const villageClientDetailsRender = (clientDetailsData) => {
        const {
            fullname,
            client_code: clientCode,
            sex: gender,
            group_leader: groupLeader,
            head_of_household: headOfHouseHold,
            zone,
            phone,
            all_enrollment: allEnrollment,
            age
        } = clientDetailsData

        const { clientBalance, lastPaymentDate } = getClientTransactionDetails(
            clientDetailsData,
            [clientDetailsData],
            transactions
        )

        return (
            <>
                <div className="header-bar" id="header-bar">
                    <Container>
                        <TopBar back backNavigation={backNavigationHandler}>
                            {t('village.village')} {villageName}
                        </TopBar>
                    </Container>
                </div>
                <CSSTransition
                    unmountOnExit
                    timeout={constants.ANIMATION_TIMEOUT}
                    in={animate}
                    classNames="generic"
                    appear
                    onEnter={() => setAnimationList(true)}
                    nodeRef={nodeRef}
                >
                    <div
                        className="village-client-details-parent page-wrapper"
                        data-testid="client-details-page"
                        ref={nodeRef}
                    >
                        <Container>
                            <Row>
                                <Col md={12} lg={4}>
                                    <ClientStatus
                                        status={
                                            client.duplicate
                                                ? ClientStatusEnum.DUPLICATE
                                                : client.client_status
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <CardClientDetails
                                        clientCode={clientCode}
                                        clientPhone={phone}
                                        interactionHandler={interactionHandler}
                                        otherClientData={{
                                            [t('gender')]: translateGender(
                                                gender,
                                                t
                                            ),
                                            [t('age')]: age,
                                            [t('groupRole')]: groupLeader,
                                            [t('familyLeader')]:
                                                headOfHouseHold,
                                            [t('zone')]: zone || '-'
                                        }}
                                        preCertified={isClientCertified}
                                        country={country}
                                        balance={clientBalance}
                                        isClientDuplicate={client?.duplicate}
                                        clientStatus={client.client_status}
                                    >
                                        {fullname}
                                    </CardClientDetails>
                                </Col>
                                <Col md={8} className="display-flex fd-col">
                                    <Col
                                        md={12}
                                        className={
                                            animationList
                                                ? 'clear-h-padding flex-1 village-payment--col flex-0 animate-show-5 animate-showed-5'
                                                : 'clear-h-padding flex-1 village-payment--col flex-0 animate-show-5'
                                        }
                                    >
                                        <VillageClientPayment
                                            paymentSummary={buildPaymentSummaryProps(
                                                {
                                                    allEnrollment,
                                                    balance: clientBalance
                                                },
                                                lastPaymentDate
                                                    ? t('dateMonthYear', {
                                                          date: new Date(
                                                              lastPaymentDate
                                                          )
                                                      })
                                                    : '-',
                                                country
                                            )}
                                            isAnyDelivered={isAnyDelivered(
                                                clientOrders
                                            )}
                                        />
                                    </Col>
                                    <Col
                                        md={12}
                                        className={
                                            animationList
                                                ? 'clear-h-padding flex-0 animate-show-3 animate-showed-3'
                                                : 'clear-h-padding flex-0 animate-show-3'
                                        }
                                    >
                                        <VillageClientPacket
                                            goalItems={clientPackages}
                                            allowModification={canModifyClient}
                                            modifyHandler={() =>
                                                modifyHandler(
                                                    constants
                                                        .CLIENT_MODIFICATION_TYPES
                                                        .PACKAGES
                                                )
                                            }
                                            finisher={
                                                clientBalance >= allEnrollment
                                            }
                                        />
                                    </Col>
                                    {displayVisitButton && (
                                        <div className="visit-new-container">
                                            <Button
                                                className="visit-new-btn"
                                                style="primary"
                                                onClick={openVisitPageHandler}
                                                data-testid="button-new-visit"
                                                id="btn-new-visit"
                                            >
                                                {t('enrollment.newVisit')}
                                            </Button>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </CSSTransition>
                <Modal
                    showModal={displayModal}
                    closeClickHandler={() => setDisplayModal(false)}
                    hideButtons
                    showCloseIconBtn
                    position="center"
                    className="duplicate-confirm-modal"
                    data-testid="mark-client-duplicate-modal"
                >
                    <ConfirmDuplicateFlagInteraction
                        clientCode={client?.client_code}
                        clientNames={client?.fullname}
                        handleOnClick={markClientAsDuplicate}
                    />
                </Modal>
            </>
        )
    }

    const ClientLoading = () => (
        <Container>
            <Row>
                <Col md={12}>
                    <div className="loading-wrapper">
                        <p className="loading-text">
                            {t('village.partialLoaderMsg')}
                        </p>
                        <Spinner size="120" />
                    </div>
                </Col>
            </Row>
        </Container>
    )

    if (!client || loadingClient) {
        return <ClientLoading />
    }

    return villageClientDetailsRender(client)
}
