import connectApi from 'api/connectApi'
import {
    RxCollection,
    RxDatabase,
    RxReplicationWriteToMasterRow,
    lastOfArray
} from 'rxdb'
import {
    RxReplicationState,
    replicateRxCollection
} from 'rxdb/plugins/replication'
import { Subscription } from 'rxjs'
import {
    UpdateDocumentType,
    CheckpointType,
    CollectionDefinition,
    RxDocType,
    SubscribeDocumentType
} from './rxTypes'
import { sortBy } from 'lodash'
import { internetConnect } from '../helpers'

class Collection {
    definition: CollectionDefinition
    replicationState?: RxReplicationState<RxDocType, CheckpointType>
    intervalId?: NodeJS.Timeout
    rxCollection?: RxCollection
    private _db?: RxDatabase
    isLoading = false
    subscriptions = new Map<string, Subscription>()

    constructor(definition: CollectionDefinition, db: RxDatabase) {
        this.definition = definition
        this._db = db
    }

    async create() {
        await this._db?.addCollections({
            [this.definition.name]: {
                schema: this.definition.schema,
                migrationStrategies: this.definition.migrationStrategies
            }
        })

        this.rxCollection = this._db?.[this.definition.name]
    }

    async startSync(
        params: { [key: string]: string | number | boolean | string[] } = {}
    ) {
        const replicationIdentifier = `${this.definition.name}-${process.env.REACT_APP_API_BASE_URL}`
        this.replicationState = replicateRxCollection({
            collection: this.rxCollection,
            replicationIdentifier,
            live: true,
            retryTime: this.definition.retryTime,
            waitForLeadership: true,
            autoStart: true,
            deletedField: this.definition.deletedField,
            pull: this.definition.pullEndpoint
                ? {
                      handler: async (
                          lastCheckpoint: CheckpointType,
                          batchSize
                      ) => this.pullHandler(lastCheckpoint, batchSize, params),
                      batchSize: this.definition.batchSize
                  }
                : undefined,
            push: this.definition.pushEndpoint
                ? {
                      handler: this.pushHandler.bind(this),
                      batchSize: this.definition.batchSize
                  }
                : undefined
        })

        this.replicationState.error$.subscribe((err) => {
            console.error('replication error', err)
        })

        this.startReSync()
    }

    startReSync() {
        this.intervalId = setTimeout(async () => {
            if (internetConnect()) {
                this.replicationState?.awaitInSync()
                this.replicationState?.reSync()
            }

            this.startReSync()
        }, this.definition.reSyncInterval)
    }

    async stopSync() {
        if (this.intervalId) {
            clearInterval(this.intervalId)
            this.intervalId = undefined
        }

        await this.replicationState?.awaitInSync()
        await this.replicationState?.cancel()
    }

    async find(params = {}) {
        const documents = await this.rxCollection?.find(params).exec()
        return documents?.map((document) => document._data) || []
    }

    async upsert(document: UpdateDocumentType) {
        await this.rxCollection?.upsert(document)
    }

    async remove(id = '') {
        if (id) {
            await this.rxCollection?.findOne(id).remove()
        } else {
            await this.rxCollection?.remove()
        }
    }

    hasStopped() {
        if (!this.replicationState) return true
        return this.replicationState.isStopped()
    }

    unsubscribe(params = {}) {
        const key = JSON.stringify(params)
        this.subscriptions.get(key)?.unsubscribe()
        this.subscriptions.delete(key)
    }

    subscribe(
        fetchCallback: (documents: SubscribeDocumentType[]) => void,
        loadingCallback?: (isLoading: boolean) => void,
        params = {}
    ) {
        if (loadingCallback) {
            this.replicationState?.active$.subscribe((isActive) => {
                if (this.isLoading === isActive) return
                this.isLoading = isActive
                loadingCallback(isActive)
            })
        }

        const key = JSON.stringify(params)
        if (this.subscriptions.has(key)) {
            this.unsubscribe(params)
        }

        const subscription = this.rxCollection
            ?.find(params)
            .$.subscribe((documents) => {
                fetchCallback(
                    documents.map(
                        (document) => document._data
                    ) as SubscribeDocumentType[]
                )
            })

        if (subscription) {
            this.subscriptions.set(key, subscription)
        }
    }

    createCheckpoint = (
        collection: CollectionDefinition,
        documents: SubscribeDocumentType[]
    ) => {
        const idField = collection.idField as keyof SubscribeDocumentType
        const updatedAtField =
            collection.updatedAtField as keyof SubscribeDocumentType
        const lastDocument = lastOfArray(
            sortBy(documents, updatedAtField)
        ) as SubscribeDocumentType

        return {
            [collection.idField]: lastDocument[idField],
            date_modified: collection.formatModifiedDate(
                (lastDocument[updatedAtField] || '0') as string
            )
        }
    }

    async pullHandler(
        lastCheckpoint: CheckpointType,
        batchSize: number,
        params: { [key: string]: string | number | boolean | string[] }
    ) {
        const lastDateModified = lastCheckpoint?.date_modified || 0
        const response = await connectApi.get(this.definition.pullEndpoint, {
            params: {
                limit: batchSize,
                [this.definition.filterField]: lastDateModified,
                ...this.definition.params,
                ...params
            }
        })
        const documents = this.definition.extractFromResponse(response)

        const checkpoint =
            documents.length === 0
                ? lastCheckpoint
                : this.createCheckpoint(
                      this.definition,
                      documents as SubscribeDocumentType[]
                  )

        return {
            documents,
            checkpoint
        }
    }

    async pushHandler(documents: RxReplicationWriteToMasterRow<RxDocType>[]) {
        await Promise.all(
            documents.map(async ({ newDocumentState }) => {
                const payload = this.definition.modifier
                    ? await this.definition.modifier(newDocumentState)
                    : newDocumentState

                !(
                    this.definition.preventDelete &&
                    payload[this.definition.deletedField]
                ) &&
                    (await connectApi.post(
                        this.definition.pushEndpoint as string,
                        payload
                    ))
            })
        )

        return documents.map((doc) => ({
            ...doc.newDocumentState,
            _deleted: false
        }))
    }

    async bulkUpsert(documents: UpdateDocumentType[]) {
        await this.rxCollection?.bulkUpsert(documents)
    }
}

export default Collection
