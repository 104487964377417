import { createRxDatabase, RxDatabase, isRxDatabase } from 'rxdb'
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie'
import Collection from './Collection'
import { COLLECTION_DEFINITIONS } from './constants'
import {
    CollectionDefinition,
    QueryParams,
    SubscribeDocumentType,
    UpdateDocumentType
} from './rxTypes'
import { GetParams } from './apiTypes'

export class PersistentStorage {
    private _db: RxDatabase | undefined
    // eslint-disable-next-line no-use-before-define
    static instance: PersistentStorage | undefined
    static createPromise: Promise<unknown> | undefined
    collections: { [name: string]: Collection } = {}

    static async get() {
        if (!PersistentStorage.instance) {
            PersistentStorage.instance = new PersistentStorage()
            PersistentStorage.createPromise = PersistentStorage.instance.create(
                {}
            )
            await PersistentStorage.createPromise
        }
        if (PersistentStorage.createPromise) {
            await PersistentStorage.createPromise
        }

        return PersistentStorage.instance
    }

    async create({ ignoreDuplicate = false }) {
        const DATABASE_NAME = 'myAgroConnectDb'
        const DB_TYPE = {
            name: DATABASE_NAME,
            storage: getRxStorageDexie(),
            ignoreDuplicate
        }
        this._db = await createRxDatabase(DB_TYPE)
        PersistentStorage.createPromise = undefined
    }

    async addCollectionByName(collectionName: string) {
        const definition = COLLECTION_DEFINITIONS.find(
            (definition: CollectionDefinition) =>
                collectionName.startsWith(definition.name)
        )

        if (definition) {
            await this.addCollection({ ...definition, name: collectionName })
        }
    }

    async addCollection(definition: CollectionDefinition) {
        const collection = this.collections[definition.name]

        if (!collection && this._db) {
            const collection = new Collection(definition, this._db)
            await collection.create()
            this.collections[definition.name] = collection
        }
    }

    hasStopped(collectionName: string) {
        const collection = this.collections[collectionName]

        if (collection) {
            return collection.hasStopped()
        }

        return true
    }

    find(collectionName: string, params: {} = {}) {
        const collection = this.collections[collectionName]
        return collection.find(params)
    }

    upsert(collectionName: string, newData: UpdateDocumentType) {
        const collection = this.collections[collectionName]
        return collection.upsert(newData)
    }

    remove(collectionName: string, id = '') {
        const collection = this.collections[collectionName]
        return collection.remove(id)
    }

    unsubscribe(collectionName: string, params: {} = {}) {
        const collection = this.collections[collectionName]
        collection?.unsubscribe(params)
    }

    subscribe(
        collectionName: string,
        fetchCallback: (documents: SubscribeDocumentType[]) => void,
        loadingCallback?: (isLoading: boolean) => void,
        params: {} = {}
    ) {
        const collection = this.collections[collectionName]
        collection?.subscribe(fetchCallback, loadingCallback, params)
    }

    startSync(
        collectionName: string,
        params: { [key: string]: string | number | boolean | string[] } = {}
    ) {
        const collection = this.collections[collectionName]
        collection?.startSync(params)
    }

    async stopSync(collectionName: string) {
        const collection = this.collections[collectionName]
        await collection?.stopSync()
    }

    async destroy() {
        await Promise.all(
            Object.values(this.collections).map((collection) => {
                return collection.stopSync()
            })
        )

        await this._db?.remove()
        await this._db?.destroy()
        this._db = undefined
        PersistentStorage.instance = undefined
    }

    exists() {
        return !!this._db && isRxDatabase(this._db)
    }

    async bulkUpsert(collectionName: string, documents: UpdateDocumentType[]) {
        const collection = this.collections[collectionName]
        await collection?.bulkUpsert(documents)
    }

    async syncCollection(
        getParams: GetParams,
        queryParams: QueryParams | {},
        collectionName: string,
        subscribeCallback: (documents: SubscribeDocumentType[]) => void,
        loadingSubscribeCallback?: (isLoading: boolean) => void
    ) {
        this.startSync(collectionName, getParams)
        this.subscribe(
            collectionName,
            subscribeCallback,
            loadingSubscribeCallback,
            queryParams
        )
    }
}
