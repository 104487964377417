import React from 'react'
import './clientGoalItem.css'
import { amountFormatter, getConnectUser } from 'helpers'

export type GroupedGoalItems = {
    productId: string
    productName: string
    price: number
    imageUrl: string
    nbOfGoalItems: number
}

export const ClientGoalItem = ({
    goalItem,
    color
}: {
    goalItem: GroupedGoalItems
    color: string
}) => {
    const { country } = getConnectUser()
    return (
        <div className="client-goal-item-container">
            <div
                className="client-goal-item-borders"
                style={{ borderColor: color }}
            >
                <p className="client-goal-item-name">{goalItem.productName}</p>
                <img
                    className="client-goal-item-img"
                    src={goalItem.imageUrl}
                    alt={goalItem.productName}
                    onError={(
                        event: React.SyntheticEvent<HTMLImageElement, Event>
                    ) =>
                        ((event.target as HTMLImageElement).style.display =
                            'none')
                    }
                />
            </div>
            <p className="client-goal-item-price" style={{ color }}>
                {amountFormatter(goalItem.price, country)}
            </p>
            <p
                className="client-goal-item-units"
                style={{ backgroundColor: color }}
            >
                {goalItem.nbOfGoalItems}
            </p>
        </div>
    )
}
