import { CardPaymentPayloadType } from './cardTypes'

export enum PaymentNotificationEnum {
    FETCHING_PAYMENT_NOTIFICATION = 'fetching payment notification',
    FETCH_PAYMENT_NOTIFICATION_SUCCESS = 'fetch payment notification success',
    FETCH_PAYMENT_NOTIFICATION_FAILURE = 'fetch payment notification failure',
    FILTERED_PAYMENT_NOTIFICATION = 'filtered payment notification',
    SEARCHED_PAYMENT_NOTIFICATION = 'searched payment notification'
}

export enum NotificationEnum {
    FETCHING_NOTIFICATION = 'fetching notification',
    FETCH_NOTIFICATION_SUCCESS = 'fetch notification success',
    FETCH_NOTIFICATION_FAILURE = 'fetch notification failure',
    MARK_NOTIFICATION_READ = 'mark notification read',
    HAS_UNREAD_NOTIFICATIONS = 'has unread notifications'
}

export type PaymentPayloadType = {
    paymentData: CardPaymentPayloadType[] | []
    loading?: boolean
    error?: string
}

export type NotificationType = {
    id: number
    uuid?: string
    user_id: string
    delivery_date: string
    type: string
    sub_type: string
    message_en: string
    message_fr: string
    read_status: boolean
    created_date: string
    updated_at: string
}
export type NotificationPayloadType = {
    notificationList: NotificationType[] | []
    loading?: boolean
    error?: string
    hasUnreadNotifications?: boolean
}

type FetchingNotificationType = {
    type: NotificationEnum.FETCHING_NOTIFICATION
}

type FetchNotificationSuccess = {
    type: NotificationEnum.FETCH_NOTIFICATION_SUCCESS
    payload: NotificationPayloadType
}

type FetchNotificationFailure = {
    type: NotificationEnum.FETCH_NOTIFICATION_FAILURE
    payload: string
}

type MarkNotificationRead = {
    type: NotificationEnum.MARK_NOTIFICATION_READ
    payload: NotificationType[]
}

type HasUnreadNotifications = {
    type: NotificationEnum.HAS_UNREAD_NOTIFICATIONS
    payload: boolean
}

type FetchingPaymentNotificationType = {
    type: PaymentNotificationEnum.FETCHING_PAYMENT_NOTIFICATION
}

type FetchPaymentNotificationSuccess = {
    type: PaymentNotificationEnum.FETCH_PAYMENT_NOTIFICATION_SUCCESS
    payload: PaymentPayloadType[]
}

type FetchPaymentNotificationFailure = {
    type: PaymentNotificationEnum.FETCH_PAYMENT_NOTIFICATION_FAILURE
    payload: string
}

type FilteredPaymentNotificationType = {
    type: PaymentNotificationEnum.FILTERED_PAYMENT_NOTIFICATION
    payload: CardPaymentPayloadType[]
}

type SearchedPaymentNotificationType = {
    type: PaymentNotificationEnum.SEARCHED_PAYMENT_NOTIFICATION
    payload: CardPaymentPayloadType[]
}

export type PaymentNotificationActionType =
    | FetchingPaymentNotificationType
    | FetchPaymentNotificationSuccess
    | FetchPaymentNotificationFailure
    | FilteredPaymentNotificationType
    | SearchedPaymentNotificationType

export type NotificationActionType =
    | FetchingNotificationType
    | FetchNotificationSuccess
    | FetchNotificationFailure
    | MarkNotificationRead
    | HasUnreadNotifications
